import { Button, Modal } from "react-bootstrap";

export function CustomModal(props: any) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h4> {props.sub_header}</h4>
        <p>{props.text}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{props.button_text}</Button>
      </Modal.Footer>
    </Modal>
  );
}
