import { Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";

import Loading from "./Loading";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import Services from "./Services";
import Info from "./Info";
import Contact from "./Contact";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import translationEN from "../locales/en/translation.json";
import translationNL from "../locales/nl/translation.json";

import "./Main.css";

const resources = {
  nl: {
    translation: translationNL,
  },
  en: {
    translation: translationEN,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "nl",
  fallbackLng: "nl",
  interpolation: {
    escapeValue: false,
  },
});

export default function Main() {
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();

  useEffect(() => {
    setTimeout(() => setLoading(false), 2800);
    const ln = localStorage.getItem("userLanguage");
    if (ln) {
      i18n.changeLanguage(ln);
    }
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <div className="container_2">
        <div className="header_">
          <Header />
        </div>
        <div className="content">
          <hr className="custom-hr" />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about-me" element={<Info />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </>
  );
}
