// @ts-ignore
import { ReactComponent as Logo } from "../../assets/createch-minds-logo.svg";
import "./Loading.css";

export default function Loading() {
  return (
    <div id="loader-container">
      <Logo className="loading_logo" />
    </div>
  );
}
