import { useTranslation } from "react-i18next";

import { NavLink } from "react-router-dom";
import "./Header.css";
import Form from "react-bootstrap/Form";
export default function Header() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const handleLanguageChange = (e: any) => {
    const newLang = e.target.value;
    i18n.changeLanguage(newLang);
    localStorage.setItem("userLanguage", newLang);
  };

  return (
    <>
      <img src="images/logo_next.png" className="header_image" />
      <ul className="header">
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <NavLink to="/services">{t("services")}</NavLink>
        </li>
        <li>
          <NavLink to="/about-me">{t("who_am_i")}</NavLink>
        </li>
        <li>
          <NavLink to="/contact">{t("contact_me")}</NavLink>
        </li>
        <li>
          <Form.Select
            id="lang_select"
            value={i18n.language}
            onChange={handleLanguageChange}
          >
            <option value="nl">NL</option>
            <option value="en">EN</option>
          </Form.Select>
        </li>
      </ul>
    </>
  );
}
