import { useNavigate } from "react-router-dom";
import "./Services.css";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
export default function Services() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container fluid id="container_11">
      <h1 id="services_h1">{t("services")}</h1>
      <Row
        style={{ marginTop: "20px", gap: "40px" }}
        className="justify-content-center"
      >
        <Col id="serv_1">
          <Card className="card border-light mb-3">
            <Card.Img
              variant="top"
              src="images/laptop_1.jpg"
              width={200}
              height={250}
            />
            <Card.Body>
              <Card.Title>{t("services_2")}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                {t("services_3")}
              </Card.Subtitle>
              <Card.Text id="serv_2">
                {t("services_4")}
                <br></br>
                {t("services_5")}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col id="serv_1">
          <Card className="card border-light mb-3">
            <Card.Img
              variant="top"
              src="images/studying.jpg"
              width={200}
              height={250}
            />
            <Card.Body>
              <Card.Title>{t("services_9")}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                {t("services_10")}
              </Card.Subtitle>
              <Card.Text id="serv_2">
                {t("services_11")}
                <br></br>
                {t("services_12")}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col id="serv_1">
          <Card className="card border-light mb-3">
            <Card.Img
              variant="top"
              src="images/question.jpg"
              width={200}
              height={250}
            />
            <Card.Body>
              <Card.Title>{t("services_6")}</Card.Title>
              <Card.Text id="serv_2">
                {t("services_7")}
                <br></br>
                {t("services_8")}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="justify-content-center" style={{ marginTop: "20px" }}>
        <Col xs="auto" className="no-padding">
          <Button id="button_group_2_1" onClick={() => navigate("/contact")}>
            <b>{t("contact_me_1")}</b>
          </Button>
        </Col>
        <Col xs="auto" className="no-padding">
          <Button
            id="button_group_2_2"
            variant="outline-dark"
            onClick={() => navigate("/about-me")}
          >
            <b>{t("who_am_i")}</b>
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
