import { useNavigate } from "react-router-dom";
// @ts-ignore
import { ReactComponent as DownArrow } from "../../assets/down_arrow.svg";
import "./Home.css";
import { Button } from "react-bootstrap";
import {
  IoMdHelpCircleOutline,
  IoMdPhonePortrait,
  IoMdBook,
} from "react-icons/io";
import { CgWebsite } from "react-icons/cg";
import { useTranslation } from "react-i18next";
export default function Home() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const data = (
    <div className="second_box_home">
      <div className="container-fluid">
        <div className="row" style={{ height: "100%" }}>
          <div className="col-12">
            <div id="custom_header_1">{t("welcome")}</div>
            <br></br>
            <div id="custom_header_2">
              <IoMdHelpCircleOutline style={{ marginRight: "10px" }} />
              {t("welcome_1")}
            </div>
            <div id="custom_header_2">
              <CgWebsite style={{ marginRight: "10px" }} />
              {t("welcome_2")}
            </div>
            <div id="custom_header_2">
              <IoMdPhonePortrait style={{ marginRight: "10px" }} />
              {t("welcome_3")}
            </div>
            <div id="custom_header_2">
              <IoMdBook style={{ marginRight: "10px" }} />
              {t("welcome_4")}
            </div>

            <div id="button_group_1">
              <Button
                id="button_group_1_1"
                size="lg"
                onClick={() => navigate("/services")}
              >
                <b>{t("services_1")}</b>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className="main_box_home">
      {data}
      {/* <div
        className="down_button_area_home"
        onClick={() => navigate("/services")}
      >
        <DownArrow className="down_arrow_home" />
      </div> */}
    </div>
  );
}
