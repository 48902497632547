import { Link, useNavigate } from "react-router-dom";
import "./Info.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import { CiLinkedin } from "react-icons/ci";
import { linkedInLink } from "../../constants";
import { useTranslation } from "react-i18next";

export default function Info() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="wrapper">
            <div className="testimonial">
              <article>
                <h1>{t("who_am_i")}</h1>
                <img src="images/profile_picture_thomas.jpg" />
                <blockquote>Consultant Software Engineer</blockquote>
                <h5>
                  Thomas Vankerckhoven
                  <Link to={linkedInLink} target="_blank">
                    <CiLinkedin color="white" size={20} />
                  </Link>
                </h5>
                <p>{t("who_am_i_1")}</p>
                <p>{t("who_am_i_2")}</p>
                <p>{t("who_am_i_3")}</p>
                <div style={{ marginTop: "40px", marginBottom: "40px" }}>
                  <Button
                    id="button_group_4_1"
                    // size="lg"
                    onClick={() => navigate("/services")}
                  >
                    {t("services")}
                  </Button>
                  <Button
                    variant="outline-light"
                    id="button_group_4_2"
                    // size="m"
                    onClick={() => navigate("/contact")}
                  >
                    {t("contact_me_1")}
                  </Button>
                </div>
              </article>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
