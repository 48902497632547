import { useNavigate } from "react-router-dom";
import "./Contact.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useState } from "react";
import { CustomModal } from "../Utils";
import { FORM_ENDPOINT } from "../../constants";

import { MdOutlinePermPhoneMsg } from "react-icons/md";
import { AiOutlineFieldNumber } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { CiMap } from "react-icons/ci";
import { useTranslation } from "react-i18next";
export default function Contact() {
  const { t } = useTranslation();

  const [errorModalShow, setErrorModalShow] = useState(false);
  const [successModalShow, setSuccessModalShow] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const inputs = e.target.elements;
    const data: any = {};

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
    }

    fetch(FORM_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (!response.ok) {
        setErrorModalShow(true);
      } else {
        for (let i = 0; i < inputs.length; i++) {
          if (inputs[i].name) {
            inputs[i].value = null;
          }
        }
        setSuccessModalShow(true);
      }
    });
  };

  return (
    <div className="main_box_contact">
      <CustomModal
        show={successModalShow}
        onHide={() => setSuccessModalShow(false)}
        sub_header={t("contact_1")}
        button_text={t("contact_2")}
        text={t("contact_3")}
      />
      <CustomModal
        show={errorModalShow}
        onHide={() => setErrorModalShow(false)}
        sub_header={t("contact_4")}
        button_text={t("contact_2")}
        text={t("contact_5")}
      />

      <div className="second_box_contact">
        <div className="container-fluid">
          <Row>
            <Col md={12} lg={8}>
              <div className="form">
                <h3>{t("contact_me_1")}</h3>
                <Form
                  action={FORM_ENDPOINT}
                  onSubmit={handleSubmit}
                  method="POST"
                >
                  <Form.Group className="mb-3">
                    <Form.Label>{t("contact_6")}</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="name"
                      placeholder={t("contact_13")}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={t("contact_14")}
                      required
                      name="email"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>{t("contact_7")}</Form.Label>
                    <Form.Control
                      as="textarea"
                      type="text"
                      rows={10}
                      required
                      name="message"
                      placeholder={t("contact_15")}
                    />
                  </Form.Group>

                  <Button
                    size="lg"
                    id="button_group_5_1"
                    variant="primary"
                    type="submit"
                  >
                    {t("contact_8")}
                  </Button>
                </Form>
              </div>
            </Col>
            <Col md={12} lg={4}>
              <div className="info">
                <center>
                  <h3>Createch Minds</h3>
                </center>
                <hr></hr>
                <span>
                  <AiOutlineFieldNumber
                    size={15}
                    style={{ marginRight: "3px" }}
                  />
                  <span id="info_text">{t("contact_9")}</span> 1002.531.325
                </span>
                <br></br>
                <span>
                  <CiMap size={15} style={{ marginRight: "3px" }} />
                  <span id="info_text">{t("contact_10")}</span>{" "}
                  Werktuigkundigenstraat 33 bus 2, Oostende 8400
                </span>
                <MapContainer
                  style={{ height: "300px", width: "100%", marginTop: "30px" }}
                  center={[51.2321819, 2.9401937]}
                  zoom={14}
                  scrollWheelZoom={false}
                >
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={[51.2321819, 2.9401937]}>
                    <Popup>{t("contact_11")}</Popup>
                  </Marker>
                </MapContainer>
                <br></br>
                <br></br>
                <center>
                  <h4>Thomas Vankerckhoven</h4>
                </center>
                <hr></hr>
                <span>
                  <MdOutlinePermPhoneMsg
                    size={15}
                    style={{ marginRight: "3px" }}
                  />
                  <span id="info_text">{t("contact_12")}</span> +32 487 76 97 87
                </span>
                <br></br>
                <span>
                  <AiOutlineMail size={15} style={{ marginRight: "3px" }} />
                  <span id="info_text">Email</span>{" "}
                  <a href="mailto:thomas.vankerckhoven@createch-minds.be">
                    thomas.vankerckhoven@createch-minds.be
                  </a>
                </span>

                <br></br>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
