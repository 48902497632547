import Main from "./components/Main";
import React from "react";
import ReactDOM from "react-dom/client";

import "./css/variables.css";
import "./css/index.css";
import { HashRouter } from "react-router-dom";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <HashRouter>
    <I18nextProvider i18n={i18next}>
      <Main />
    </I18nextProvider>
  </HashRouter>
);
